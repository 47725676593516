<template>
  <div
    class="ReadView"
    :class="{ fullscreenReadOnMobile }">
    <div
      ref="scrollContainer"
      class="scrollContainer">
      <StepRead
        v-if="loaded"
        ref="stepRead"
        :step-id="stepId"
        :no-banner-margin-on-mobile="fullscreenReadOnMobile">
        <template #progress>
          <ProgressBar
            :progress="progress"
            disable-animation />
        </template>
      </StepRead>
      <div
        v-if="showBottomGradient"
        class="bottomGradientPlaceholder">
        <div class="bottomGradient" />
      </div>
      <div
        v-if="showContinueButton"
        class="continueButtonAnchor">
        <div
          class="continueButton --global-clickable"
          @click="navigateContinue()">
          {{ $t('continue') }}
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  continue: 'Continue'
  continue_no: 'Fortsett'
</translations>

<script>
import { EventBus } from '@/event-bus';
import ResizeObserver from 'resize-observer-polyfill';

import ProgressBar from '@/components/ProgressBar';
import StepRead from '@/modules/course/components/StepRead';
import MemomapsOnboardingPopup from '@/modules/course/components/MemomapsOnboardingPopup';

export default {
    name: 'ReadView',
    components: { StepRead, ProgressBar },
    props: {
        stepId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            progress: 0
        };
    },
    computed: {
        loaded() {
            return this.$store.state.moduleCourse.courses[this.$route.params.courseId].loadInfo.content;
        },
        fullscreenReadOnMobile() {
            return !this.$store.state.moduleApp.debugSettings.noFullscreenReadOnMobile;
        },
        showBottomGradient() {
            const step = this.$store.state.moduleCourse.steps[this.stepId];
            return !(step.activityNames.length === 1 && step.activityNames[0] === 'read');
        },
        showContinueButton() {
            const step = this.$store.state.moduleCourse.steps[this.stepId];
            return step.activityNames.length === 1 && step.activityNames[0] === 'read' && step.read.progress === 1;
        }
    },
    created() {
        this.observer = new ResizeObserver(this.handleResize);
    },
    mounted() {
        this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
        EventBus.$on('resize-end', this.handleResize);
        this.observer.observe(this.$refs.stepRead.$el);
        this.handleResize();
        if (!this.$store.state.moduleApp.memolife.didShowMemomapsOnboardingPopup) {
            this.showMemomapsOnboardingPopup();
        }
    },
    beforeDestroy() {
        this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
        EventBus.$off('resize-end', this.handleResize);
        this.observer.unobserve(this.$refs.stepRead.$el);
    },
    methods: {
        navigateContinue() {
            EventBus.$emit('stepFlowNext');
        },
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const scrollTop = scrollContainer.scrollTop;
            const scrollHeight = scrollContainer.scrollHeight;
            const clientHeight = scrollContainer.clientHeight;

            if (scrollHeight === clientHeight) {
                this.progress = 1;
                this.$emit('progress', 1);
            } else {
                this.progress = scrollTop / (scrollHeight - clientHeight);
                this.$emit('progress', scrollTop / (scrollHeight - clientHeight));
            }
        },
        handleResize() {
            this.handleScroll();
        },
        showMemomapsOnboardingPopup() {
            this.$modal
                .open({
                    component: MemomapsOnboardingPopup,
                    target: 'app-modal'
                })
                .waitDone()
                .then(message => {
                    this.$store.dispatch('moduleApp/markMemomapsOnboardingAsShown');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.ReadView {
}
.StepRead {
    margin: auto;
}

.scrollContainer {
    overflow-y: auto;
    height: 100%;
}

.ProgressBar {
    /deep/ .indicator {
        background-color: $completionColor;
    }
}

.bottomGradientPlaceholder {
    position: sticky;
    bottom: 0;
    height: 0;
    pointer-events: none;
    width: 100%;
    margin: auto;
    z-index: 2;
}
.bottomGradient {
    position: absolute;
    height: 8em;
    right: 0;
    left: 0;
    bottom: 0;

    background: linear-gradient(0deg, $course-articleBackground 4em, rgba($course-articleBackground, 0));
}

.continueButtonAnchor {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: $course-articleBackground;
}
.continueButton {
    position: absolute;
    bottom: 1em;
    color: black;
    font-weight: 600;
    padding: 1.5em 1em;
    background-color: #e8e8e8;

    width: 100%;
    max-width: 43em; // Adjustment from outer padding
    margin: auto;
    text-align: center;

    @include media('<mobile') {
        width: calc(100% - 2em);
    }
}
</style>
