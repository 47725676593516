<template>
  <div class="RtVideoPlayer">
    <div :style="{ paddingTop: `${(1 / aspectRatio) * 100}%` }" />
    <div
      ref="host"
      class="viewport" />
  </div>
</template>

<script>
import YouTubePlayer from 'youtube-player';

export default {
    inject: ['theme'],
    props: {
        youtubeId: {
            type: String,
            required: true
        },
        aspectRatio: {
            type: Number,
            default: 16 / 9
        }
    },
    mounted() {
        this.player = YouTubePlayer(this.$refs.host, {
            height: '100%',
            width: '100%',
            videoId: this.youtubeId,
            playerVars: {
                autoplay: 0,
                rel: 0,
                modestbranding: 1,
                // fs: 0,
                host: 'https://www.youtube.com',
                //
                enablejsapi: 1,
                origin: location.origin
            }
        });
    }
};
</script>

<style lang="scss" scoped>
.RtVideoPlayer {
    position: relative;
    background-color: rgba(black, 0.05);
}
.RtVideoPlayer /deep/ > .viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>
