<template>
  <div
    class="StyledArticle"
    :class="`theme-${themeId}`">
    <slot />
  </div>
</template>

<script>
export default {
    props: {
        themeId: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
@mixin colorize($color) {
    color: mix(black, $color, 70%);
    $headerColor: $color;

    h1 {
        color: $headerColor;
    }
    h2 {
        color: $headerColor;
    }
    h3 {
        color: $headerColor;
    }
    h4 {
        color: $headerColor;
    }
    p {
        color: mix(black, $color, 70%);
    }
    li::before {
        color: $headerColor;
    }
}

.StyledArticle {
    word-wrap: break-word;

    &.theme- {
        @include colorize(black);
    }

    @each $lifeAreaId, $color in $lifeAreaColors {
        &.theme-#{$lifeAreaId} {
            @include colorize($color);
        }
    }
    &.theme- {
        @include colorize($dashboard-topbarBgColor);
    }
    &.theme-inverted {
        $headerColor: white;
        $color: white;
        color: mix(black, $color, 10%);
        h1 {
            color: $headerColor;
        }
        h2 {
            color: $headerColor;
        }
        h3 {
            color: $headerColor;
        }
        h4 {
            color: $headerColor;
        }
        p {
            color: mix(black, $color, 10%);
        }
        li::before {
            color: $headerColor;
        }

        a {
            color: mix(black, $color, 10%);
        }
    }

    h1 {
        letter-spacing: -0.015em;
        padding-top: 2.5em;
        font-size: 1.8em;
    }
    h2 {
        letter-spacing: -0.015em;
        line-height: 1.15em;
        padding-top: 1em;
        font-size: 1.6em;
    }

    h3 {
        display: inline;

        &::before {
            display: block;
            content: '';
            margin-top: 1.33em;
        }
        &::after {
            display: inline;
            content: ' ';
            margin-top: 1.33em;
        }

        & + p {
            display: inline;

            &::after {
                display: block;
                content: '';
                margin-bottom: 1.33em;
            }
        }
    }

    p {
        letter-spacing: 0.006em;
        font-family: 'Times New Roman';
    }

    h3,
    p {
        font-size: 1.1em;
        line-height: 1.35em;
    }

    ul {
        list-style: none;
        padding-left: 1.5em;
    }
    ol {
        list-style: none;
        counter-reset: li;
        padding-left: 1.5em;
    }
    li {
        margin-top: 1em;

        & > p {
            display: inline;
        }
    }
    ol li {
        counter-increment: li;
    }
    // ol[type="a"] li {
    // }
    ol[type='a'] > li::before {
        content: '.' counter(li, lower-latin);
        direction: rtl;
    }
    li::before {
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
    }
    ol > li::before {
        content: '.' counter(li);
        direction: rtl;
    }
    ul > li::before {
        content: '\2022';
        font-size: 130%;
        vertical-align: middle;
    }

    ol.ol--upper-alpha {
        & > li::before {
            content: '.' counter(li, upper-alpha);
        }
    }

    .compactList li {
        margin-top: 0em;
    }
    .list--hideBullets {
        & > ul,
        & > ol {
            & > li::before {
                display: none;
            }
        }
    }
    .list--useCheckmarks {
        & > ul > li::before {
            content: '\2713';
        }
    }

    img {
        max-width: 100%;
        margin: auto;
        display: block;

        &[alt='rightalignhack'] {
            float: right;
            margin-left: 1em;
        }
    }

    strong {
    }
}

@include media('<mobile') {
    .StyledArticle {
        h3,
        p {
            font-size: 1.25em;
        }
    }
}
</style>
