<template>
  <div class="RtPptxPlayer">
    <div
      class="openButton --global-clickable"
      @click="openWidget()">
      <div
        class="content"
        :style="style">
        <div
          v-if="title"
          class="title"
          v-html="title" />
        <div
          v-if="names"
          class="names"
          v-html="names" />
        <div class="spacer" />
        <div class="playIcon">
          <svgicon
            class="icon"
            icon="play-triangle" />
        </div>
        <div class="spacer" />
        <div class="faces">
          <div
            v-for="(faceUrl, i) of faces"
            :key="i"
            class="Face"
            :style="{ backgroundImage: `url(${faceUrl})` }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PptxWidgetPopup from '@/components/PptxWidgetPopup';

export default {
    props: {
        url: {
            type: String,
            required: true
        },
        title: {
            type: String
        },
        persons: {
            type: Array
        },
        thumb: {
            type: String
        }
    },
    computed: {
        names() {
            return this.persons.map(p => p.name).join('<span class="divider"> | </span>');
        },
        faces() {
            return this.persons.map(p => p.image).filter(f => f);
        },
        style() {
            if (this.thumb) {
                return {
                    backgroundImage: `url(${this.thumb})`
                };
            }
            return null;
        }
    },
    methods: {
        openWidget() {
            this.$modal.open({
                component: PptxWidgetPopup,
                target: 'app-modal-full',
                props: {
                    url: this.url
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.RtPptxPlayer {
    margin: 2em 0;
}

.openButton {
    position: relative;
    background-color: rgba(black, 0.05);
    border-radius: 0.3em;

    &::after {
        content: '';
        display: block;
        padding-bottom: 50%;
    }
}
.content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    background-position: center;
    background-size: cover;
}
.playIcon {
    $size: 3em;
    width: $size;
    height: $size;
    // fill: rgba(black, 0.3);
    border-radius: 50%;
    background-color: $standardButtonColor;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    $size: 1.5em;
    width: $size;
    height: $size;
    fill: rgba(white, 0.9);
    margin-left: 0.2em;
}

.title {
    color: rgba(black, 0.8);
}
.names {
    font-size: 80%;
    color: rgba(black, 0.5);
    margin-top: 0.3em;

    /deep/ .divider {
        color: rgba(black, 0.1);
    }
}
.spacer {
    flex: 1;
}

.faces {
    display: flex;
}
.Face {
    $size: 2.5em;
    width: $size;
    height: $size;
    border-radius: 50%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:not(:first-child) {
        margin-left: 1em;
    }
}
</style>
