<template>
  <div class="WhySection">
    <div
      v-if="you"
      class="Part">
      <div
        class="Part__label"
        :style="{ color: theme.color }">
        {{ $t('whyYou') }}
      </div>
      <WhySectionList
        class="Part__list"
        :items="you" />
    </div>
    <div
      v-if="brain"
      class="Part">
      <div
        class="Part__label"
        :style="{ color: theme.color }">
        {{ $t('whyBrain') }}
      </div>
      <WhySectionList
        class="Part__list"
        :items="brain" />
    </div>
  </div>
</template>

<translations>
  whyYou: 'HVORFOR DU VIL ELSKE HVERDAGEN'
  whyBrain: 'HVORFOR HJERNEN DIN VIL ELSKE DEG'
</translations>

<script>
import WhySectionList from './WhySectionList';

export default {
    components: { WhySectionList },
    props: {
        you: {
            type: Array
        },
        brain: {
            type: Array
        }
    },
    inject: ['theme']
};
</script>

<style lang="scss" scoped>
.WhySection {
}
.Part {
    padding: 1em;
    &:not(:first-child) {
        margin-top: 1em;
    }
}
.Part__label {
    font-size: 120%;
    color: orange;
}
.Part__list {
    margin-top: 1em;
}

@include media('>=mobile') {
    .Part__label {
        font-size: 140%;
    }
    .Part__list {
        font-size: 120%;
    }
}
</style>
