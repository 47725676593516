<template>
  <div
    class="RtQuote"
    :style="{ xbackgroundColor: theme.color }">
    <div class="inner">
      <div
        class="text"
        style="font-style: italic;">
        <slot name="text" />
      </div>
      <div class="source">
        <slot name="source" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['theme']
};
</script>

<style lang="scss" scoped>
.RtQuote {
    margin: 2em 1em;
}
.inner {
    max-width: 24em;
    margin: auto;
}
.text {
    font-size: 120%;
    line-height: 1.5em;
}
.source {
    margin-top: 1em;
    font-size: 90%;
    text-align: right;
}
</style>
