<template>
  <div
    class="StepRead"
    :class="{
      locked,
      hasImage: bannerImage,
      hasSubtitle: step.subtitle,
      noBannerMarginOnMobile,
      noBannerMarginOnDesktop
    }">
    <WidthWrapper set-vars-on-parent />

    <div
      v-if="bannerImage"
      :style="{ backgroundImage: `url(${bannerImage}` }"
      class="image" />

    <div class="stickyHeaderContainer">
      <div class="stickyHeader">
        <div
          class="stickyImage"
          :style="{ backgroundImage: `url(${bannerImage}` }" />
        <div class="stickyTitle">
          <span v-html="step.title" />
        </div>

        <div class="progressContainer">
          <slot name="progress" />
        </div>
      </div>
    </div>

    <div class="headerSection">
      <div class="body">
        <div
          class="stepNumber"
          :class="{ hasNoNumber: !step.number }"
          :style="{ backgroundColor: theme.color }">
          <div class="stepNumberLabel">
            {{ $t('step') }}
          </div>
          <div class="stepNumberNumber">
            {{ step.number }}
          </div>
        </div>

        <!-- <StepAuthor
          v-if="step.author"
          v-bind="step.author"
        />
        <StepAuthors
          v-else-if="step.authors"
          :authors="step.authors"
        /> -->

        <div class="title">
          <span v-html="step.title" />
        </div>
        <div class="subtitle">
          <span v-html="step.subtitle" />
        </div>

        <div
          v-if="showAboutAuthor"
          class="aboutAuthorSection">
          <AboutAuthor class="author" />
        </div>

        <MemoIntro
          v-if="memoIntroMemo"
          class="memoIntro"
          :memo="memoIntroMemo" />
      </div>
    </div>

    <div class="body">
      <div v-if="step.intro">
        <RuntimeTemplate
          class="text"
          :template="step.intro"
          :wrap="runtimeTemplateWrap"
          :components="componentsForRuntimeTemplate"
          :pass-to-template="propsForTemplate" />
      </div>

      <WhySection
        v-if="step.why"
        v-bind="step.why" />
      <RuntimeTemplate
        v-if="stepReadText"
        class="text"
        :class="{ cutoff: locked }"
        :template="stepReadText"
        :wrap="runtimeTemplateWrap"
        :components="componentsForRuntimeTemplate"
        :pass-to-template="propsForTemplate" />
    </div>
    <div class="footer">
      <div
        v-if="locked"
        class="upgradeButtonContainer">
        <UpgradeButton />
      </div>
      <div
        v-else
        class="completionContainer">
        <StandardButton
          v-if="step.read.progress < 1"
          :label="$t('markAsRead')"
          pulsate
          @click="markAsRead()" />
        <StandardButton
          v-else
          :label="$t('readDone')"
          class="completionIndicator"
          icon="checkmark" />
      </div>
    </div>
  </div>
</template>

<translations>
  step: 'Step'
  step_no: 'Steg'

  markAsRead: 'Mark as read'
  markAsRead_no: 'Marker som lest'

  readDone: 'Article read'
  readDone_no: 'Artikkelen lest'
</translations>

<script>
import Color from 'color';
import RuntimeTemplate from '@/components/RuntimeTemplate';
import StandardButton from '@/components/StandardButton';

import UpgradeButton from '@/modules/dashboard/components/UpgradeButton';
import WidthWrapper from '@/modules/dashboard/components/WidthWrapper';
import AboutAuthor from '@/modules/course/components/AboutAuthor';

import { getCategoryIdFromLifeskillId } from '@/utils';

import MemoIntro from './MemoIntro';
import StyledArticle from './StyledArticle';
import WhySection from './WhySection';
import RtQuote from './RtQuote';
import RtOptional from './RtOptional';
import RtImageCarousel from './RtImageCarousel';
import RtPptxPlayer from './RtPptxPlayer';
import RtVideoPlayer from './RtVideoPlayer';
import RtMnemonicImages from './RtMnemonicImages';

const lifeskillImages = require.context('@/assets/images/', true, /\.(png|jpg)$/);

const componentsForRuntimeTemplate = {
    StyledArticle: StyledArticle,
    'rt-quote': RtQuote,
    'rt-optional': RtOptional,
    'rt-image-carousel': RtImageCarousel,
    'rt-pptx-player': RtPptxPlayer,
    'rt-video-player': RtVideoPlayer,
    'rt-mnemonic-images': RtMnemonicImages
};

export default {
    name: 'StepRead',
    components: { RuntimeTemplate, MemoIntro, WhySection, UpgradeButton, StandardButton, WidthWrapper, AboutAuthor },
    props: {
        stepId: {
            type: String,
            required: true
        },
        noBannerMarginOnMobile: {
            type: Boolean,
            default: true
        },
        noBannerMarginOnDesktop: {
            type: Boolean,
            default: true
        }
    },
    provide() {
        return {
            theme: this.theme
        };
    },
    data() {
        // const step = this.$store.state.moduleCourse.steps[this.stepId]
        // const categoryId = getCategoryIdFromLifeskillId(step.fromLifeskill || this.$store.state.moduleCourse.courses[step.courseId].lifeskillNumber)
        // const theme = categoryThemes[categoryId] || { color: '#5081ff' }
        return {
            // $dashboard-topbarBgColor
            theme: { color: '#113978' }
        };
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        themeId() {
            return getCategoryIdFromLifeskillId(
                this.step.fromLifeskill || this.$store.state.moduleCourse.courses[this.step.courseId].lifeskillNumber
            );
        },
        bannerImage() {
            if (this.step.image) {
                return this.step.image;
            } else if (this.step.number === '0') {
                try {
                    const course = this.$store.state.moduleCourse.courses[this.step.courseId];
                    return lifeskillImages(`./lifeskill-backgrounds-large/${course.lifeskillNumber}.jpg`);
                } catch (err) {
                    return null;
                }
            }
            return null;
        },
        memoIntroMemo() {
            if (this.step.memorize && this.step.memorize.memoIds.length === 1 && !this.step.hideMnemonicTeaser) {
                const memo = this.$store.state.moduleCourse.memos[this.step.memorize.memoIds[0]];
                return memo;
            }
            return null;
        },
        showAboutAuthor() {
            // Quick fix: step.number as '0' on lifeskill 41
            return this.step.number === '0' && this.stepId !== 'b85ec695-9761-45a0-8dbb-d43f6239977f';
        },
        locked() {
            return !this.$can('view', this.step);
        },
        stepReadText() {
            if (!this.step.read || !this.step.read.text) {
                return null;
            }
            const m = this.step.read.text.match(/<cutoff( close="(.*?)")?>(.*?)<\/cutoff>/);
            if (this.locked) {
                if (m) {
                    const closeTag = m[2];
                    const append = m[3] || '';
                    return this.step.read.text.substr(0, m.index) + append + (closeTag ? `</${closeTag}>` : '');
                }
                return null;
            } else {
                if (m) {
                    return this.step.read.text.substr(0, m.index) + this.step.read.text.substr(m.index + m[0].length);
                }
                return this.step.read.text;
            }
        },
        runtimeTemplateWrap() {
            return { name: 'StyledArticle', props: { xthemeId: this.themeId } };
        },
        propsForTemplate() {
            return {};
        }
    },
    created() {
        this.componentsForRuntimeTemplate = componentsForRuntimeTemplate;
    },
    mounted() {
        this.$el.style.setProperty('--theme-primary-color', this.theme.color);
        this.$el.style.setProperty('--theme-text-color', Color(this.theme.color).mix(Color('black'), 0.7));
    },
    methods: {
        markAsRead() {
            this.$store.dispatch('moduleCourse/markStepReadAsDone', { stepId: this.stepId });
        }
    }
};
</script>

<style lang="scss" scoped>
$padding: 1em;

.StepRead {
    background-color: $course-articleBackground;
    padding: $padding;
    padding-bottom: 5em;

    &.hasImage {
        .stepNumber {
            position: relative;
            margin-top: -3em;
        }
    }

    &.hasSubtitle {
        .title {
            text-transform: uppercase;
            span {
                font-weight: 600;
            }
        }
        .subtitle {
            span {
                font-size: 150%;
            }
        }
    }
}

.image {
    background-size: cover;
    background-position: center;
    border-radius: $padding * 0.7;
    max-height: calc(100vh - 18em);

    &::after {
        content: '';
        display: block;
        padding-top: percentage(9 / 16);
    }
}

.author {
    // margin: auto;
    // margin-bottom: 1em;
}
.StepAuthor {
    margin-bottom: 1em;
    color: rgba(white, 0.8);
}

.aboutAuthorSection {
    border-top: 1px solid rgba(black, 0.05);
    border-bottom: 1px solid rgba(black, 0.05);
    display: flex;
    justify-content: center;
    padding: 1em;

    margin-top: 3em;
    margin-bottom: -2em;
}

.title {
    text-align: center;
    // max-width: 30em;
    color: rgba(black, 0.9);

    span {
        font-size: 200%;
    }
}
.subtitle {
    margin-top: 1em;
    text-align: center;
    color: rgba(black, 0.9);
    span {
        font-size: 200%;
    }
}

.MemoIntro {
    margin-top: 2em;
}

.WhySection {
    margin-top: 3em;
}

.body {
    position: relative;
    max-width: 43em;
    margin: auto;
}

.stepNumber {
    $size: 6em;
    width: $size;
    height: $size;
    border-radius: 50%;
    color: white;

    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 1em;

    &.hasNoNumber {
        visibility: hidden;
    }
}

.stepNumberNumber {
    text-transform: uppercase;
    font-size: 160%;
}
.stepNumberLabel {
    font-size: 80%;
    text-transform: uppercase;
}

.upgradeButtonContainer {
    text-align: center;
}

.text {
    margin-top: 2em;
    &.cutoff::after {
        content: '';
        position: relative;
        display: block;
        margin-top: -8em;
        height: 8em;
        width: 100%;
        background-image: linear-gradient(
            to bottom,
            #{rgba($course-articleBackground, 0)} 0,
            #{$course-articleBackground} 100%
        );
    }
}

.footer {
    max-width: 43em;
    margin: auto;
    border-top: 1px solid rgba(black, 0.05);
    border-bottom: 1px solid rgba(black, 0.05);
    padding: 2em 0;
    margin-top: 2em;
}

.completionContainer {
    padding-top: 1em;
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.completionIndicator {
    cursor: default;
}

.headerSection {
    background: linear-gradient(0deg, #ffffff00, $course-articleBackground 3em);
    position: relative;
    z-index: 2;
    padding: 0.1em;
    margin-left: -1em;
    margin-right: -1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 3em;
}

$stickyHeaderHeight: 6.2em;
.stickyHeaderContainer {
    height: 0;
    position: sticky;
    top: 0;
    margin-left: -1em;
    margin-right: -1em;
    z-index: 1;
}
.stickyHeader {
    position: relative;
    height: $stickyHeaderHeight;
    background-color: white;
    display: flex;
    align-items: center;
}
.progressContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.stickyImage {
    height: $stickyHeaderHeight;
    width: $stickyHeaderHeight * 1.4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 1em;
    flex-shrink: 0;
}

.stickyTitle {
    color: rgba(black, 0.9);
    flex: 1;
    span {
        font-size: 120%;
        font-weight: 600;
    }
}
.stickySubtitle {
    color: rgba(black, 0.7);
    margin-top: 0.3em;
    span {
        font-size: 110%;
    }
}

.WidthWrapper {
    width: 100%;
    max-width: 43em - 2em; // Adjustment from outer padding
    margin: auto;
}

@include media('<mobile') {
    .WidthWrapper {
        width: calc(100% - 2em);
    }
}

// -----------------------------------------
// Responive
// -----------------------------------------

@include media('>=#{$articleBreakpoint}') {
    .StepRead {
        &.hasSubtitle {
            .title {
                span {
                    font-size: 300%;
                }
            }
        }
    }
}

@include media('>=mobile') {
    .StepRead {
        &.noBannerMarginOnDesktop {
            .image {
                border-radius: 0;
                margin: #{-$padding} #{-$padding} 0 #{-$padding};
            }
        }
    }
    .text {
        font-size: 140%;
    }
}

@include media('<mobile') {
    .StepRead {
        &.noBannerMarginOnMobile {
            .image {
                border-radius: 0;
                margin: #{-$padding} #{-$padding} 0 #{-$padding};

                &::after {
                    content: '';
                    display: block;
                    padding-top: 70%;
                }
            }
        }
    }
    .image {
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }
    .stepNumber {
        font-size: 80%;
    }

    $stickyHeaderHeight: 3em;
    .stickyHeader {
        height: $stickyHeaderHeight;
        padding-right: 1em;
    }

    .stickyImage {
        height: $stickyHeaderHeight;
        width: $stickyHeaderHeight * 1.4;
    }

    .stickyTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
