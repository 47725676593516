<template>
  <div class="RtImageCarousel">
    <div class="itemContainer">
      <img
        class="image"
        :src="images[index]">
    </div>
    <div class="nav">
      <div
        class="navButton --global-clickable"
        @click="prev()">
        {{ $t('prev') }}
      </div>
      <div>{{ index + 1 }}/{{ images.length }}</div>
      <div
        class="navButton --global-clickable"
        @click="next()">
        {{ $t('next') }}
      </div>
    </div>
  </div>
</template>

<translations>
  prev: 'Prev'
  prev_no: 'Forrige'
  next: 'Next'
  next_no: 'Neste'
</translations>

<script>
import Color from 'color';

export default {
    inject: ['theme'],
    props: {
        images: {
            type: Array
        }
    },
    data() {
        return {
            index: 0
        };
    },
    computed: {
        backgroundColor() {
            return Color(this.theme.color).fade(0.9);
        }
    },
    methods: {
        prev() {
            if (this.index === 0) {
                return;
            }
            this.index -= 1;
        },
        next() {
            if (this.index === this.images.length - 1) {
                return;
            }
            this.index += 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.RtImageCarousel {
    // padding: 1em 0;
    font-size: var(--app-font-size);

    // border: 1px solid green;
}
.image {
    width: 100%; //em;
}
.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(black, 0.8);
}
.navButton {
    padding: 0.5em 1em;
}
</style>
