<template>
  <div class="RtMnemonicImages">
    <div class="images">
      <div
        v-for="image of images"
        :key="image"
        class="Image">
        <MnemonicImage
          :image="image"
          :focus="true" />
      </div>
    </div>
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    computed: {
        images() {
            return this.items;
        }
    }
};
</script>

<style lang="scss" scoped>
.RtMnemonicImages {
}
.images {
    display: flex;
    justify-content: center;
}
.Image {
    $size: 4em;
    width: $size;
    height: $size;
    margin: 0 0.5em;
    border-radius: 4px;
    overflow: hidden;
}
</style>
