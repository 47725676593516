<template>
  <div class="ProgressBar">
    <div
      class="indicator"
      :class="{ disableAnimation }"
      :style="style" />
  </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true
        },
        disableAnimation: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        style() {
            return {
                transform: `scale(${this.progress}, 1)`
                // backgroundColor: 'white'
            };
        }
    }
};
</script>

<style scoped lang="scss">
$correctColor: #93c60b;

.ProgressBar {
}
.indicator {
    background-color: $correctColor;
    transform-origin: top left;
    height: 0.2em;
    width: 100vw;

    &:not(.disableAnimation) {
        transition: transform 0.5s;
    }
}
</style>
