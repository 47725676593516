<template>
  <div class="MemoIntro">
    <div class="images">
      <div
        v-for="image of images"
        :key="image"
        class="Image">
        <MnemonicImage
          :image="image"
          :focus="true" />
      </div>
    </div>
    <!-- <div class="text" v-t="'text'"></div> -->
  </div>
</template>

<translations>
  text: 'Klikk ''memo'' nederst i artikkelen for å lære det viktigste i steget med morsomme husketeknikker.'
</translations>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: ['memo'],
    computed: {
        images() {
            return this.memo.fantasies[0].mnemonics
                .slice(0, 3)
                .map(mnemonic => mnemonic.numbershape || mnemonic.imageUrl);
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoIntro {
}
.text {
    line-height: 1.5em;
    color: rgba(black, 0.5);
    font-style: italic;
    font-size: 90%;
    text-align: center;
}
.images {
    display: flex;
    justify-content: center;
}
.Image {
    $size: 4em;
    width: $size;
    height: $size;
    margin: 0 0.5em;
    border-radius: 4px;
    overflow: hidden;
}
</style>
