// Based on https://github.com/alexjoverm/v-runtime-template/blob/master/index.js

function wrap(template, wrap) {
    if (!wrap) {
        return template;
    }
    return `<${wrap.name} v-bind="wrapProps">${template}</${wrap.name}>`;
}

export default {
    props: {
        template: String,
        wrap: Object,
        passToTemplate: Object,
        components: Object
    },
    render(h) {
        if (this.template) {
            const { $options } = this.$parent;

            const props = this.passToTemplate || {};
            const propKeys = Object.keys(props);

            if (this.wrap) {
                props.wrapProps = this.wrap.props;
                propKeys.push('wrapProps');
            }

            const dynamic = {
                template: wrap(this.template, this.wrap) || '<div></div>',
                methods: $options.methods,
                props: propKeys,
                computed: $options.computed,
                components: this.components || $options.components
            };

            return h(dynamic, {
                props
            });
        }
    }
};
