<template>
  <div
    class="RtOptional"
    :class="{ collapsed }">
    <StyledArticle
      class="text"
      theme-id="inverted">
      <slot name="head" />
    </StyledArticle>

    <div
      v-if="collapsed"
      class="readMoreContainer">
      <div
        v-if="!noExtraCta"
        class="readMoreQuestion">
        {{ $t('readmoreQuestion') }}
      </div>
      <div
        class="readMoreButton --global-clickable"
        @click="expand()">
        {{ $t('readmoreButton') }}
      </div>
      <svgicon
        icon="chevron-down"
        class="--global-clickable"
        @click="expand()" />
    </div>

    <StyledArticle
      v-else
      class="text"
      theme-id="inverted">
      <slot name="tail" />
    </StyledArticle>
  </div>
</template>

<translations>
  readmoreQuestion: Interested in brain science?
  readmoreQuestion_no: Klar for mer hjerneprat?
  readmoreButton: Click to read more
  readmoreButton_no: Klikk for å lese mer
</translations>

<script>
import StyledArticle from './StyledArticle';

export default {
    components: { StyledArticle },
    props: {
        noExtraCta: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            collapsed: true
        };
    },
    mounted() {
        if (!this.$slots.tail) {
            this.collapsed = false;
        }
    },
    methods: {
        expand() {
            this.collapsed = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.RtOptional {
    background-color: #181a38;

    margin-left: calc(var(--widthWrapperSideWidth) * -1px);
    margin-right: calc(var(--widthWrapperSideWidth) * -1px);
    padding-left: calc(var(--widthWrapperSideWidth) * 1px + 2em);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px + 2em);

    padding-top: 1px;
    padding-bottom: 1px; // Will force the p margin to stay within its container
}

.text {
    font-size: 90%;
}
.readMoreContainer {
    color: white;
    text-align: center;
    padding-top: 2em;
    padding-bottom: 1em;

    .svg-icon {
        $size: 2em;
        width: $size;
        height: $size;
        margin-top: 1em;
        fill: rgba(white, 0.2);

        animation: bounce 3s infinite;

        @keyframes bounce {
            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-0.7em);
            }
            60% {
                transform: translateY(-0.3em);
            }
        }
    }
}
.readMoreQuestion {
    font-weight: 600;
}
.readMoreButton {
    color: #5faddf; // $standardButtonColor;
    font-weight: 600;
    padding: 0.3em;
}
</style>
