<template>
  <div
    class="PptxWidgetPopup"
    @click="$emit('signal', 'done')">
    <div class="container">
      <svgicon
        class="loadingIndicator"
        icon="loadwheel" />
      <iframe
        class="iframe"
        :src="url"
        frameborder="0"
        scrolling="no" />
    </div>

    <div
      class="exitButton --global-clickable"
      @click="$emit('signal', 'done')">
      <svgicon
        class="svg-icon"
        icon="close" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.PptxWidgetPopup {
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    position: relative;
    max-width: 60em;
    max-height: 100%;
    width: 100%;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: '';
        display: block;
        padding-top: 56.5%; // Aspect ration of the presentation
        padding-bottom: 23px; // The height of the navbar in the pptx iframe
    }
}

.iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loadingIndicator {
    $size: 2em;
    width: $size;
    height: $size;
    fill: rgba(black, 0.8);
    animation: rotate360 2s linear infinite;
}
@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.exitButton {
    position: absolute;
    top: 0.5em;
    right: 1em;
    background-color: black;
    padding: 1em;
    border-radius: 50%;

    .svg-icon {
        $size: 0.8em;
        display: block;
        width: $size;
        height: $size;
        fill: white;
    }
}
</style>
