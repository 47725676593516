<template>
  <div class="WhySectionList">
    <div
      v-for="(item, i) of items"
      :key="i"
      class="Item">
      <svgicon
        class="Item__checkmark"
        icon="checkmark" />{{ item }}
    </div>
  </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array
        }
    }
};
</script>

<style lang="scss" scoped>
.WhySectionList {
    color: var(--theme-text-color);
}
.Item {
    &:not(:first-child) {
        margin-top: 0.5em;
    }

    padding-left: 2em;
    text-indent: -2em;
}
.Item__checkmark {
    $size: 1em;
    width: $size;
    height: $size;
    margin-right: 1em;
    fill: var(--theme-primary-color);
}
</style>
